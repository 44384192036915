.wrapper {
  display: inline-flex;
  flex-direction: column;
  background-color: rgba(255,255,255,.2);
  border-radius: .5rem;
  margin: .5rem;
  padding: .5rem;
  width: 18rem;
}
.section {
  margin: .5rem;
}
.charDetails {
  display: flex;
  justify-content: space-between;
}
