.wrapper {
  display: flex;
}
.infoBar {
  display: flex;
  flex-direction: column;
}
.wrapper a {
  text-decoration: none;
  color: inherit;
}
.crewLink {
  text-decoration: none;
}
.titleBar {
  display: flex;
  align-items: flex-start;
  height: 2rem;
}
.storageSpace {
  display: flex;
  flex-wrap: wrap;
}
