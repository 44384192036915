.wrapper {
  list-style-type: none;
  padding: 0 .5rem;
  margin: .5rem 0;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: .1rem;
}
.row span {
  margin-right: .5rem;
}
.row svg:hover {
  color: red;
}
.removeButton {
  cursor: pointer;
  height: 15;
  width: 15;
}