@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Russo+One&display=swap);
:root {
  --main-text-color: #1cd6be;
  --main-bg-color: #040424;
}

body {
  margin: 0;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
}

#root {
  height: 100%;
  color: var(--main-text-color);
  background-color: var(--main-bg-color);
}

* {
  position: relative;
}

a {
  text-decoration: none;
  color: inherit;
}

.heading_heading__2i3Kf {
  font-family: 'Russo One', sans-serif;
  margin: 0;
}
.heading_noFont__3Lj4O {
  font-family: inherit;
}

.header_wrapper__qgCxK {
  display: flex;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
  padding: .25rem 3rem;
  background-color: rgba(0,0,0,.2);
}

.github_wrapper__2LYNf {
  background: #fff;
  border-radius: 100%;
  height: 3rem;
  width: 3rem;
}

.github_logo__1ic0a {
  height: 2.5rem;
  width: 2.5;
  margin: .25rem;
}

.footer_wrapper__1C--_ {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background-color: rgba(0,0,0,.4);
  height: 2rem;
}

.layout_wrapper__ixEjb {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.button_button__3MTwP {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #102143;
  font-family: 'Russo One', sans-serif;
  font-size: 90%;
  border-top: 2px solid #a9f3f3;
  border-bottom: 2px solid #a9f3f3;
  border-left: 0;
  border-right: 0;
  color: #a9f3f3;
  padding: .25rem .5rem;
  cursor: pointer;
  text-decoration: none;
  border-radius: .25rem;
  min-width: 4rem;
}
.button_button__3MTwP:hover {
  background-color: #a9f3f3;
  color: #102143;
}

.upload_msg__1O_dC {
  display: inline-block;
}
.upload_error__2P5G4 {
  color: red;
}
.upload_notice__3GZKP {
  color: white;
}
.upload_warning__3lVCy {
  color: yellow;
}

.page_wrapper__1wO3F {
  flex-grow: 1;
  overflow: hidden;
}
.page_internal__1hyjw {
  display: flex;
}
.page_mainSection__28DiR {
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1rem;
}
.page_center__2bm4Z {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.page_withScroll__p_JO7 {
  overflow: scroll;
}

.navlink_box__OdQqZ {
  font-size: 70%;
  font-weight: 600;
  line-height: 1.2;
  cursor: pointer;
}
.navlink_box__OdQqZ, .navlink_box__OdQqZ a {
  text-decoration: none;
}
.navlink_inner__k_XDU {
  display: flex;
  height: 3rem;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: var(--main-text-color);
}
.navlink_active__1Qr78 .navlink_inner__k_XDU {
  background: rgba(255,255,255,.2);
  color: white;
}

.sidebar_wrapper__2zrJm {
  width: 3rem;
  padding: .5rem;
}
.sidebar_list__313eR {
  list-style: none;
  padding: 0;
}
.card_wrapper__2NX3z {
  padding: .5rem;
  border-radius: .5rem;
  margin: .5rem;
}
.card_inline__3rPcc {
  display: inline-block;
  align-items: flex-start;
}
.card_lighten__1US7G {
  background-color: rgba(255,255,255,.2);
}
.card_darken__1dHKE {
  background-color: rgba(0,0,0,.2);
}

.buttonbar_wrapper__3hyr6 {
  display: flex;
  justify-content: space-between;
}

.crewstatbar_barWrapper__1rRZi {
  width: 95%;
  border: 1px solid var(--main-bg-color);
  height: 1rem;
}
.crewstatbar_barWrapper__1rRZi span {
  position: absolute;
  font-size: 90%;
  top: -.1rem;
  left: .25rem;
}

.crewconditions_wrapper__NhhSv {
  list-style-type: none;
  padding: 0 .5rem;
  margin: .5rem 0;
}
.crewconditions_row__1oYCy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: .1rem;
}
.crewconditions_row__1oYCy span {
  margin-right: .5rem;
}
.crewconditions_row__1oYCy svg:hover {
  color: red;
}
.crewconditions_removeButton__1PYts {
  cursor: pointer;
  height: 15;
  width: 15;
}
.crewmember_wrapper__1hOg5 {
  display: inline-flex;
  flex-direction: column;
  background-color: rgba(255,255,255,.2);
  border-radius: .5rem;
  margin: .5rem;
  padding: .5rem;
  width: 18rem;
}
.crewmember_section__1uh4G {
  margin: .5rem;
}
.crewmember_charDetails__1L_fm {
  display: flex;
  justify-content: space-between;
}

.shipcrew_wrapper__2qdSs {
  display: flex;
}

.researchflask_wrapper__MlBN6 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 120%;
}
.researchflask_wrapper__MlBN6 > span {
  padding: .4rem;
  cursor: pointer;
  font-weight: 600;
  -webkit-user-select: none;
          user-select: none;
}
.researchflask_flask__2LOR7 {
  font-size: 90%;
}
.researchflask_iconWrapper__3beLz {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 90%;
}
.researchflask_level1__1mw_z {
  color: green;
}
.researchflask_level2__2TkY- {
  color: yellow;
}
.researchflask_level3__3IPj4 {
  color: red;
}

.researchitem_progress__2SqTc {
  width: 95%;
  border-radius: .5rem;
  height: 1rem;
  overflow: hidden;
  margin: 0 auto;
  border: 1px solid var(--main-bg-color);
}
.researchitem_progressBar__31_nO {
  height: 100%;
  position: absolute;
  background-color: green;
}
.researchitem_progressMark__yAbEH {
  top: -.05rem;
  left: .5rem;
  font-size: 90%;
  display: block;
  position: absolute;
}
.researchitem_levelBar__2mdWs {
  display: flex;
  justify-content: space-around;
  background-color: rgba(255,255,255,.2);
  border-radius: .5rem;
  padding: .25rem;
}
.researchitem_wrapper__1Z6Ug {
  min-width: 15rem;
  min-height: 8rem;
  padding: .5rem;
  margin: .5rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1rem;
  background-color: rgba(255,255,255,.2);
}
.researchitem_active__3Z94t {
  background-color: rgba(255,125,255,.2);
}
.researchitem_finished__2UOAz {
  min-height: 3rem !important;
}

.storagerow_input__1eScw {
  height: 1.5rem;
  width: 100%;
}
.storagerow_nameField__7i2x4 {
  width: 80%;
}

.storage_wrapper__1j-dn {
  width: 15rem;
}
.storage_table__2wdjG {
  width: 100%;
  padding: .5rem;
}

.ship_wrapper__3yeAy {
  display: flex;
}
.ship_infoBar__2eB7C {
  display: flex;
  flex-direction: column;
}
.ship_wrapper__3yeAy a {
  text-decoration: none;
  color: inherit;
}
.ship_crewLink__3a9hh {
  text-decoration: none;
}
.ship_titleBar__qvvWH {
  display: flex;
  align-items: flex-start;
  height: 2rem;
}
.ship_storageSpace__Gj7Zy {
  display: flex;
  flex-wrap: wrap;
}

.shipimage_image__1c9dT {
  image-rendering: pixelated;
  transform: rotate(90deg);
}

.refcard_table__D-20D {
  border-collapse: collapse;
}
.refcard_table__D-20D th {
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}
.refcard_table__D-20D td, .refcard_table__D-20D th {
  border: solid 1px rgba(0,0,0,.2);
  min-width: 6rem;
  padding: .25rem;
}
.refcard_table__D-20D tr:nth-child(odd) {
  background-color: rgba(255,255,255,.1);
}

