.wrapper {
  flex-grow: 1;
  overflow: hidden;
}
.internal {
  display: flex;
}
.mainSection {
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1rem;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.withScroll {
  overflow: scroll;
}
