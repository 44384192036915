.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #102143;
  font-family: 'Russo One', sans-serif;
  font-size: 90%;
  border-top: 2px solid #a9f3f3;
  border-bottom: 2px solid #a9f3f3;
  border-left: 0;
  border-right: 0;
  color: #a9f3f3;
  padding: .25rem .5rem;
  cursor: pointer;
  text-decoration: none;
  border-radius: .25rem;
  min-width: 4rem;
}
.button:hover {
  background-color: #a9f3f3;
  color: #102143;
}
