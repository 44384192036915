.msg {
  display: inline-block;
}
.error {
  color: red;
}
.notice {
  color: white;
}
.warning {
  color: yellow;
}
