@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Russo+One&display=swap');

:root {
  --main-text-color: #1cd6be;
  --main-bg-color: #040424;
}

body {
  margin: 0;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
}

#root {
  height: 100%;
  color: var(--main-text-color);
  background-color: var(--main-bg-color);
}

* {
  position: relative;
}

a {
  text-decoration: none;
  color: inherit;
}
