.box {
  font-size: 70%;
  font-weight: 600;
  line-height: 1.2;
  cursor: pointer;
}
.box, .box a {
  text-decoration: none;
}
.inner {
  display: flex;
  height: 3rem;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: var(--main-text-color);
}
.active .inner {
  background: rgba(255,255,255,.2);
  color: white;
}
